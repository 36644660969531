<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h3>Statistique glissante</h3>
        <div class="d-flex align-items-center mt-1">
          <div
            class="cursor-pointer"
            @click="openTab = !openTab"
            v-if="stats.length > 0"
          >
            <span>
              <i :class="openTab ? 'icon-arrow-down' : 'icon-arrow-right'"></i>
            </span>
          </div>
          <div class="row ml-1">
            <div class="form-group col-sm-6">
              <label>Date de début</label>
              <datepicker
                v-model="form.begin"
                format="dd.MM.yyyy"
                language="fr"
                input-class="form-control"
              ></datepicker>
            </div>
            <div class="form-group col-sm-6">
              <label>Date de fin</label>
              <datepicker
                v-model="form.end"
                format="dd.MM.yyyy"
                language="fr"
                input-class="form-control"
              ></datepicker>
            </div>
            <div class="col-md-12">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                @click="updateForm()"
              >
                <i class="icon-eye" aria-hidden="true"></i>&nbsp; Visualiser
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-block" v-if="openTab">
        <div
          class="row p-2 mt-1"
          v-for="stat of stats"
          :key="stat.commercial.id"
        >
          <div
            class="col-md-12 rounded p-1"
            :style="
              stat.active
                ? `background-color:${stat.commercial.color}`
                : 'background-color: #808080'
            "
          >
            <el-checkbox
              v-if="stat.active !== undefined"
              v-model="stat.active"
            ></el-checkbox>
            <strong
              class="ml-1"
              :class="!stat.active ? 'text-gray-dark' : ''"
              >{{ stat.commercial.pseudo }}</strong
            >
          </div>
          <div class="col-md-2 mt-1">
            <table class="stat-table">
              <tr>
                <td>
                  Formulaires:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbForms,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbForms }}</td>
              </tr>
              <tr>
                <td>
                  Découverte crées:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbDiscovery,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbDiscovery }}</td>
              </tr>
              <tr>
                <td>
                  % découvertes crées:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.createDiscoveryPercent,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ getCreateDiscoveryPercent(stat) }} %</td>
              </tr>
              <tr>
                <td>
                  Découverte payées:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbPayedDiscovery,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbPayedDiscovery }}</td>
              </tr>
              <tr>
                <td>
                  % découvertes payées:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.discoveryPayedPercent,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ getDiscoveryPayedPercent(stat) }} %</td>
              </tr>
              <tr>
                <td>
                  CA découverte:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.caDiscovery,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ formatPrice(stat.caDiscovery, true) }}</td>
              </tr>

              <tr>
                <td>
                  % acquisition:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.acquisitionPercent,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ getAcquisitionPercent(stat) }} %</td>
              </tr>
            </table>
          </div>
          <div class="col-md-2 mt-1">
            <table class="stat-table">
              <tr>
                <td>
                  Nb pack conversion:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbPackConversion,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbPackConversion }}</td>
              </tr>
              <tr>
                <td>
                  Pack moyen:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.averageConversionSessions,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>
                  {{ stat.averageConversionSessions }}
                  {{
                    stat.averageConversionSessions > 1 ? "séances" : "séance"
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  % conversion:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.conversionPercent,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ getConversionPercent(stat) }} %</td>
              </tr>
              <tr>
                <td>
                  CA conversion:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.caConversion,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ formatPrice(stat.caConversion, true) }}</td>
              </tr>
              <tr>
                <td>
                  CA encaissé:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.caConversionPayed,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ formatPrice(stat.caConversionPayed, true) }}</td>
              </tr>

              <tr>
                <td>
                  % transformation:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.transformationPercent,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ getTransformationPercent(stat) }} %</td>
              </tr>
            </table>
          </div>
          <div class="col-md-2 mt-1">
            <table class="stat-table">
              <tr>
                <td>
                  Nb pack finis:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbEndedPack,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbEndedPack }}</td>
              </tr>
              <tr>
                <td>
                  Nb pack traités:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbTreatedPack,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbTreatedPack }}</td>
              </tr>
              <tr>
                <td>
                  Nb pack fidélisés:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbFidelisedPack,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>
                  {{ stat.nbFidelisedPack }}
                </td>
              </tr>
              <tr>
                <td>
                  % fidélisations:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.fidelisationPercent,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ getFidelisationPercent(stat) }}</td>
              </tr>
              <tr>
                <td>
                  Pack moyen:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbAverageSessionFidelisedPack,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbAverageSessionFidelisedPack }}</td>
              </tr>
              <tr>
                <td>
                  CA Fidelisation
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.caFidelisation,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ formatPrice(stat.caFidelisation, true) }}</td>
              </tr>
            </table>
          </div>

          <div class="col-md-3 mt-1">
            <table class="stat-table">
              <tr>
                <td>
                  CA Total (Hors découverte):
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.cumulateCa,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ formatPrice(stat.cumulateCa, true) }}</td>
              </tr>
              <tr>
                <td>
                  Panier moyen:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.averageCa,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ formatPrice(stat.averageCa, true) }}</td>
              </tr>
              <tr>
                <td>
                  Pack moyen:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.averagePack,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>
                  {{ stat.averagePack }}
                  {{ stat.averagePack > 1 ? "séances" : "séance" }}
                </td>
              </tr>
              <tr>
                <td>
                  Moyenne des échéances de paiements:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.averagePayments,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.averagePayments }}</td>
              </tr>
              <tr>
                <td>
                  Nb échéances Pack 100:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.average100Payments,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.average100Payments }}</td>
              </tr>
              <tr>
                <td>
                  Nb échéances Pack 40:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.average40Payments,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.average40Payments }}</td>
              </tr>
              <tr>
                <td>
                  Nb échéances Pack 20:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.average20Payments,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.average20Payments }}</td>
              </tr>
              <tr>
                <td>
                  Nb échéances Pack 10:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.average10Payments,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.average10Payments }}</td>
              </tr>
              <tr>
                <td>
                  CA Total (découverte incluse):
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.cumulateCaWithDiscovery,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>
                  {{ formatPrice(stat.cumulateCa + stat.caDiscovery, true) }}
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-3 mt-1">
            <table class="stat-table">
              <tr>
                <td>
                  Prospects perdus:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbLostProspects,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbLostProspects }}</td>
              </tr>
              <tr>
                <td>
                  Pertes après commande découverte:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbLostAfterDiscovery,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbLostAfterDiscovery }}</td>
              </tr>
              <tr>
                <td>
                  Pertes après commande conversion:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbLostAfterConversion,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbLostAfterConversion }}</td>
              </tr>
              <tr>
                <td>
                  Pertes après commande fidélisation:
                  <i
                    v-if="stat.commercial.id !== 'total'"
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content: tooltip.nbLostAfterFidelisation,
                      placement: 'top',
                    }"
                  ></i>
                </td>
                <td>{{ stat.nbLostAfterFidelisation }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    defaultStat: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
    },
  },

  data() {
    return {
      toggleEdit: false,
      openTab: false,
      tooltip: {
        nbForms: "Nombre de formulaires créés",
        nbDiscovery: "Nombre de découvertes créées",
        createDiscoveryPercent:
          "Pourcentage de découvertes créées par rapport au nombre de formulaires",
        nbPayedDiscovery: "Nombre de découvertes payées",
        discoveryPayedPercent:
          "Pourcentage de découvertes payées par rapport au nombre de découvertes créées",
        caDiscovery: "Chiffre d'affaires des découvertes",
        acquisitionPercent:
          "Pourcentage de découvertes payées par rapport au nombre de formulaires",
        nbPackConversion: "Nombre de packs convertis",
        averageConversionSessions:
          "Nombre moyen de séances des packs conversion",
        conversionPercent:
          "Nombre de packs convertis par rapport aux découvertes",
        caConversion: "Chiffre d'affaires des conversions",
        caConversionPayed: "Chiffre d'affaires des conversions payées",
        transformationPercent:
          "Pourcentage de packs convertis par rapport au nombre de formulaires",
        nbEndedPack:
          "Nombre de packs finis (dont la dernière session a été faite dans la période)",
        nbTreatedPack:
          "Nombre de packs finis ayant été traités dans le menu 'Fidelisation'",
        nbFidelisedPack:
          "Nombre de packs fidélisés (dans le menu 'Fidélisation')",
        fidelisationPercent:
          "Pourcentage de packs fidélisés par rapport aux packs finis",
        nbAverageSessionFidelisedPack:
          "Nombre moyen de séances des packs fidélisés",
        caFidelisation: "Chiffre d'affaires des fidélisations",
        cumulateCa: "Chiffre d'affaires total (hors découvertes)",
        averageCa: "Panier moyen des commandes conversions et fidélisations",
        averagePack:
          "Nombre de séances moyenne des packs conversions et fidélisations",
        averagePayments:
          "Nombre d'échéances moyen des commandes conversions et fidélisations",
        average100Payments: "Nombre de paiements moyen pour les packs: 100",
        average40Payments: "Nombre de paiements moyen pour les packs: 40",
        average20Payments: "Nombre de paiements moyen pour les packs: 20",
        average10Payments: "Nombre de paiements moyen pour les packs: 10",
        cumulateCaWithDiscovery:
          "Chiffre d'affaires total (découverte incluse)",
        nbLostProspects: "Nombre de prospects perdus dans la pèriode",
        nbLostAfterDiscovery:
          "On compte le nombre de client ayant finit un pack découverte dans la période et n'ayant pas signé de pack conversion",
        nbLostAfterConversion:
          "On compte le nombre de client ayant finit un pack conversion dans la période et n'ayant pas signé de pack fidélisation",
        nbLostAfterFidelisation:
          "On compte le nombre de client ayant finit un pack fidélisation dans la période et n'ayant pas signé de pack supplémentaire",
      },
    };
  },

  computed: {
    stats() {
      if (this.defaultStat.length === 0) return [];

      const stats = this.defaultStat.filter((stat) => stat.active);

      return [
        {
          commercial: {
            id: "total",
            pseudo: "Total",
            color: "#808080",
          },
          nbForms: stats.reduce((acc, stat) => acc + stat.nbForms, 0),
          nbDiscovery: stats.reduce((acc, stat) => acc + stat.nbDiscovery, 0),
          nbPayedDiscovery: stats.reduce(
            (acc, stat) => acc + stat.nbPayedDiscovery,
            0
          ),
          caDiscovery: stats.reduce((acc, stat) => acc + stat.caDiscovery, 0),
          nbPackConversion: stats.reduce(
            (acc, stat) => acc + stat.nbPackConversion,
            0
          ),
          averageConversionSessions: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.averageConversionSessions),
              0
            ) / stats.length
          ).toFixed(2),
          caConversion: stats.reduce((acc, stat) => acc + stat.caConversion, 0),
          caConversionPayed: stats.reduce(
            (acc, stat) => acc + stat.caConversionPayed,
            0
          ),
          nbEndedPack: stats.reduce((acc, stat) => acc + stat.nbEndedPack, 0),
          nbTreatedPack: stats.reduce(
            (acc, stat) => acc + stat.nbTreatedPack,
            0
          ),
          nbFidelisedPack: stats.reduce(
            (acc, stat) => acc + stat.nbFidelisedPack,
            0
          ),
          nbAverageSessionFidelisedPack: (
            stats.reduce(
              (acc, stat) =>
                acc + parseFloat(stat.nbAverageSessionFidelisedPack),
              0
            ) / stats.length
          ).toFixed(2),
          caFidelisation: stats.reduce(
            (acc, stat) => acc + stat.caFidelisation,
            0
          ),
          cumulateCa: stats.reduce((acc, stat) => acc + stat.cumulateCa, 0),
          averageCa: (
            stats.reduce((acc, stat) => acc + parseFloat(stat.averageCa), 0) /
            stats.length
          ).toFixed(2),
          averagePack: (
            stats.reduce((acc, stat) => acc + parseFloat(stat.averagePack), 0) /
            stats.length
          ).toFixed(2),
          averagePayments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.averagePayments),
              0
            ) / stats.length
          ).toFixed(2),
          average100Payments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.average100Payments),
              0
            ) / stats.length
          ).toFixed(2),
          average40Payments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.average40Payments),
              0
            ) / stats.length
          ).toFixed(2),
          average20Payments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.average20Payments),
              0
            ) / stats.length
          ).toFixed(2),
          average10Payments: (
            stats.reduce(
              (acc, stat) => acc + parseFloat(stat.average10Payments),
              0
            ) / stats.length
          ).toFixed(2),
          nbLostProspects: stats.reduce(
            (acc, stat) => acc + stat.nbLostProspects,
            0
          ),
          nbLostAfterDiscovery: stats.reduce(
            (acc, stat) => acc + stat.nbLostAfterDiscovery,
            0
          ),
          nbLostAfterConversion: stats.reduce(
            (acc, stat) => acc + stat.nbLostAfterConversion,
            0
          ),
          nbLostAfterFidelisation: stats.reduce(
            (acc, stat) => acc + stat.nbLostAfterFidelisation,
            0
          ),
        },
      ].concat(this.defaultStat);
    },
  },

  methods: {
    updateForm() {
      this.$emit("updateForm", this.form);
      this.openTab = true;
    },
    getCreateDiscoveryPercent(stat) {
      return stat.nbForms > 0
        ? ((stat.nbDiscovery / stat.nbForms) * 100).toFixed(2)
        : 0;
    },

    getDiscoveryPayedPercent(stat) {
      return stat.nbDiscovery > 0
        ? ((stat.nbPayedDiscovery / stat.nbDiscovery) * 100).toFixed(2)
        : 0;
    },

    getAcquisitionPercent(stat) {
      return stat.nbForms > 0
        ? ((stat.nbPayedDiscovery / stat.nbForms) * 100).toFixed(2)
        : 0;
    },

    getConversionPercent(stat) {
      return stat.nbPayedDiscovery > 0
        ? ((stat.nbPackConversion / stat.nbPayedDiscovery) * 100).toFixed(2)
        : 0;
    },

    getTransformationPercent(stat) {
      return stat.nbForms > 0
        ? ((stat.nbPackConversion / stat.nbForms) * 100).toFixed(2)
        : 0;
    },

    getFidelisationPercent(stat) {
      return stat.nbEndedPack > 0
        ? ((stat.nbFidelisedPack / stat.nbEndedPack) * 100).toFixed(2)
        : 0;
    },
  },
};
</script>

<style type="text/css" scoped>
.stat-table tr td {
  padding: 0 10px;
}

.rounded {
  border-radius: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
