import { render, staticRenderFns } from "./StatsCommercialsV2Periods.vue?vue&type=template&id=5988a0bb&scoped=true"
import script from "./StatsCommercialsV2Periods.vue?vue&type=script&lang=js"
export * from "./StatsCommercialsV2Periods.vue?vue&type=script&lang=js"
import style0 from "./StatsCommercialsV2Periods.vue?vue&type=style&index=0&id=5988a0bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5988a0bb",
  null
  
)

export default component.exports