var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("Statistique glissante")]),_c('div',{staticClass:"d-flex align-items-center mt-1"},[(_vm.stats.length > 0)?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.openTab = !_vm.openTab}}},[_c('span',[_c('i',{class:_vm.openTab ? 'icon-arrow-down' : 'icon-arrow-right'})])]):_vm._e(),_c('div',{staticClass:"row ml-1"},[_c('div',{staticClass:"form-group col-sm-6"},[_c('label',[_vm._v("Date de début")]),_c('datepicker',{attrs:{"format":"dd.MM.yyyy","language":"fr","input-class":"form-control"},model:{value:(_vm.form.begin),callback:function ($$v) {_vm.$set(_vm.form, "begin", $$v)},expression:"form.begin"}})],1),_c('div',{staticClass:"form-group col-sm-6"},[_c('label',[_vm._v("Date de fin")]),_c('datepicker',{attrs:{"format":"dd.MM.yyyy","language":"fr","input-class":"form-control"},model:{value:(_vm.form.end),callback:function ($$v) {_vm.$set(_vm.form, "end", $$v)},expression:"form.end"}})],1),_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"btn btn-sm btn-secondary",attrs:{"type":"button"},on:{"click":function($event){return _vm.updateForm()}}},[_c('i',{staticClass:"icon-eye",attrs:{"aria-hidden":"true"}}),_vm._v("  Visualiser ")])])])])]),(_vm.openTab)?_c('div',{staticClass:"card-block"},_vm._l((_vm.stats),function(stat){return _c('div',{key:stat.commercial.id,staticClass:"row p-2 mt-1"},[_c('div',{staticClass:"col-md-12 rounded p-1",style:(stat.active
              ? `background-color:${stat.commercial.color}`
              : 'background-color: #808080')},[(stat.active !== undefined)?_c('el-checkbox',{model:{value:(stat.active),callback:function ($$v) {_vm.$set(stat, "active", $$v)},expression:"stat.active"}}):_vm._e(),_c('strong',{staticClass:"ml-1",class:!stat.active ? 'text-gray-dark' : ''},[_vm._v(_vm._s(stat.commercial.pseudo))])],1),_c('div',{staticClass:"col-md-2 mt-1"},[_c('table',{staticClass:"stat-table"},[_c('tr',[_c('td',[_vm._v(" Formulaires: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbForms,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbForms,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbForms))])]),_c('tr',[_c('td',[_vm._v(" Découverte crées: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbDiscovery,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbDiscovery,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbDiscovery))])]),_c('tr',[_c('td',[_vm._v(" % découvertes crées: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.createDiscoveryPercent,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.createDiscoveryPercent,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.getCreateDiscoveryPercent(stat))+" %")])]),_c('tr',[_c('td',[_vm._v(" Découverte payées: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbPayedDiscovery,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbPayedDiscovery,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbPayedDiscovery))])]),_c('tr',[_c('td',[_vm._v(" % découvertes payées: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.discoveryPayedPercent,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.discoveryPayedPercent,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.getDiscoveryPayedPercent(stat))+" %")])]),_c('tr',[_c('td',[_vm._v(" CA découverte: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.caDiscovery,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.caDiscovery,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.formatPrice(stat.caDiscovery, true)))])]),_c('tr',[_c('td',[_vm._v(" % acquisition: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.acquisitionPercent,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.acquisitionPercent,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.getAcquisitionPercent(stat))+" %")])])])]),_c('div',{staticClass:"col-md-2 mt-1"},[_c('table',{staticClass:"stat-table"},[_c('tr',[_c('td',[_vm._v(" Nb pack conversion: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbPackConversion,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbPackConversion,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbPackConversion))])]),_c('tr',[_c('td',[_vm._v(" Pack moyen: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.averageConversionSessions,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.averageConversionSessions,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(stat.averageConversionSessions)+" "+_vm._s(stat.averageConversionSessions > 1 ? "séances" : "séance")+" ")])]),_c('tr',[_c('td',[_vm._v(" % conversion: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.conversionPercent,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.conversionPercent,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.getConversionPercent(stat))+" %")])]),_c('tr',[_c('td',[_vm._v(" CA conversion: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.caConversion,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.caConversion,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.formatPrice(stat.caConversion, true)))])]),_c('tr',[_c('td',[_vm._v(" CA encaissé: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.caConversionPayed,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.caConversionPayed,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.formatPrice(stat.caConversionPayed, true)))])]),_c('tr',[_c('td',[_vm._v(" % transformation: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.transformationPercent,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.transformationPercent,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.getTransformationPercent(stat))+" %")])])])]),_c('div',{staticClass:"col-md-2 mt-1"},[_c('table',{staticClass:"stat-table"},[_c('tr',[_c('td',[_vm._v(" Nb pack finis: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbEndedPack,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbEndedPack,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbEndedPack))])]),_c('tr',[_c('td',[_vm._v(" Nb pack traités: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbTreatedPack,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbTreatedPack,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbTreatedPack))])]),_c('tr',[_c('td',[_vm._v(" Nb pack fidélisés: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbFidelisedPack,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbFidelisedPack,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(stat.nbFidelisedPack)+" ")])]),_c('tr',[_c('td',[_vm._v(" % fidélisations: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.fidelisationPercent,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.fidelisationPercent,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.getFidelisationPercent(stat)))])]),_c('tr',[_c('td',[_vm._v(" Pack moyen: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbAverageSessionFidelisedPack,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbAverageSessionFidelisedPack,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbAverageSessionFidelisedPack))])]),_c('tr',[_c('td',[_vm._v(" CA Fidelisation "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.caFidelisation,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.caFidelisation,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.formatPrice(stat.caFidelisation, true)))])])])]),_c('div',{staticClass:"col-md-3 mt-1"},[_c('table',{staticClass:"stat-table"},[_c('tr',[_c('td',[_vm._v(" CA Total (Hors découverte): "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.cumulateCa,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.cumulateCa,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.formatPrice(stat.cumulateCa, true)))])]),_c('tr',[_c('td',[_vm._v(" Panier moyen: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.averageCa,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.averageCa,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.formatPrice(stat.averageCa, true)))])]),_c('tr',[_c('td',[_vm._v(" Pack moyen: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.averagePack,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.averagePack,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(stat.averagePack)+" "+_vm._s(stat.averagePack > 1 ? "séances" : "séance")+" ")])]),_c('tr',[_c('td',[_vm._v(" Moyenne des échéances de paiements: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.averagePayments,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.averagePayments,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.averagePayments))])]),_c('tr',[_c('td',[_vm._v(" Nb échéances Pack 100: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.average100Payments,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.average100Payments,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.average100Payments))])]),_c('tr',[_c('td',[_vm._v(" Nb échéances Pack 40: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.average40Payments,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.average40Payments,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.average40Payments))])]),_c('tr',[_c('td',[_vm._v(" Nb échéances Pack 20: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.average20Payments,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.average20Payments,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.average20Payments))])]),_c('tr',[_c('td',[_vm._v(" Nb échéances Pack 10: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.average10Payments,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.average10Payments,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.average10Payments))])]),_c('tr',[_c('td',[_vm._v(" CA Total (découverte incluse): "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.cumulateCaWithDiscovery,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.cumulateCaWithDiscovery,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(_vm.formatPrice(stat.cumulateCa + stat.caDiscovery, true))+" ")])])])]),_c('div',{staticClass:"col-md-3 mt-1"},[_c('table',{staticClass:"stat-table"},[_c('tr',[_c('td',[_vm._v(" Prospects perdus: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbLostProspects,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbLostProspects,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbLostProspects))])]),_c('tr',[_c('td',[_vm._v(" Pertes après commande découverte: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbLostAfterDiscovery,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbLostAfterDiscovery,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbLostAfterDiscovery))])]),_c('tr',[_c('td',[_vm._v(" Pertes après commande conversion: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbLostAfterConversion,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbLostAfterConversion,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbLostAfterConversion))])]),_c('tr',[_c('td',[_vm._v(" Pertes après commande fidélisation: "),(stat.commercial.id !== 'total')?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.tooltip.nbLostAfterFidelisation,
                    placement: 'top',
                  }),expression:"{\n                    content: tooltip.nbLostAfterFidelisation,\n                    placement: 'top',\n                  }"}],staticClass:"fa fa-info-circle ml-05"}):_vm._e()]),_c('td',[_vm._v(_vm._s(stat.nbLostAfterFidelisation))])])])])])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }