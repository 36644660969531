import { render, staticRenderFns } from "./SettingsTeamMembersDetails.vue?vue&type=template&id=473633ca&scoped=true"
import script from "./SettingsTeamMembersDetails.vue?vue&type=script&lang=js"
export * from "./SettingsTeamMembersDetails.vue?vue&type=script&lang=js"
import style0 from "./SettingsTeamMembersDetails.vue?vue&type=style&index=0&id=473633ca&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473633ca",
  null
  
)

export default component.exports