<template>
  <div class="p-2" v-if="grid">
    <div class="custom-card">
      <div class="row mt-1 align-items-center">
        <div class="col-md-6 text-center">
          <h6>
            {{ prospectedProportionRatio }}%<br />
            <span>des commandes ont été prospectés</span>
          </h6>
        </div>
        <div class="col-md-6 d-flex flex-column">
          <div>
            <span class="pills blue">
              {{ this.ordersOther.length }} Commande{{
                this.ordersOther.length > 1 ? "s" : ""
              }}
            </span>
          </div>
          <div class="mt-1">
            <span class="pills green"
              >{{ this.orders.length }} Prospecté{{
                this.orders.length > 1 ? "s" : ""
              }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="custom-card" v-if="totalConversion">
      <h3>CA Conversion</h3>
      <h4>{{ formatPrice(totalConversion) }}</h4>
    </div>

    <div class="custom-card" v-if="totalFidelisation">
      <h3>CA Fidélisation</h3>
      <h4>{{ formatPrice(totalFidelisation) }}</h4>
    </div>

    <div class="custom-card">
      <h3>CA Total</h3>
      <h4>{{ formatPrice(totalFidelisation + totalConversion) }}</h4>
    </div>

    <div class="custom-card">
      <h4>Client(s) prospecté(s) ayant passé commande</h4>
      <div class="row mt-1 align-items-center">
        <div class="col-md-6 text-center">
          <h6 class="mt-1">
            {{ prospectedwithOrderProportionRatio }}% <br />
            des client(s) ont passé commande
          </h6>
        </div>
        <div class="col-md-6 d-flex flex-column">
          <div>
            <span class="pills blue">
              {{ this.clientWithOrder.length }} client(s) ont passé commande
            </span>
          </div>
          <div class="mt-1">
            <span class="pills green">
              {{ this.clientWithoutOrder.length }} client(s) n'ont pas passé
              commande
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-2" v-else>
    <h4 class="mb-1">
      {{ orders.length }} commande{{ orders.length > 1 ? "s" : "" }} prospecté{{
        orders.length > 1 ? "s" : ""
      }}
    </h4>
    <p class="text-muted block mb-1">
      (Soit {{ prospectedProportionRatio }}% des commandes du départements)
    </p>
    <p v-if="totalConversion" class="mb-1">
      CA Conversion: {{ formatPrice(totalConversion) }}
    </p>
    <p v-if="totalFidelisation" class="mb-1">
      CA Fidélisation: {{ formatPrice(totalFidelisation) }}
    </p>
    <p v-if="totalConversion + totalConversion" class="mb-1">
      CA Total: {{ formatPrice(totalFidelisation + totalConversion) }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Array, required: true },
    others: { type: Array, required: true },
    grid: { type: Boolean, default: true },
  },

  data() {
    return {
      options: {
        responsive: true,
        animation: {
          animateRotate: false,
        },
        legend: {
          display: false,
        },
        cutoutPercentage: 75,
      },
    };
  },

  computed: {
    orders() {
      return this.data.map((c) => c.orders).flat();
    },

    ordersOther() {
      return this.others.map((c) => c.orders).flat();
    },

    clientWithOrder() {
      return this.data.filter((o) => o.orders.length > 0);
    },

    clientWithoutOrder() {
      return this.data.filter((o) => o.orders.length === 0);
    },

    totalConversion() {
      if (!this.orders || this.orders.length === 0) return 0;

      const orders = this.orders.filter(
        (o) => !o.isConversion && o.isFidelisation && o.isAcquisition
      );

      return orders.reduce((acc, order) => acc + order.price, 0);
    },

    totalFidelisation() {
      if (!this.orders || this.orders.length === 0) return 0;

      const orders = this.orders.filter(
        (o) => !o.isConversion && o.isFidelisation && !o.isAcquisition
      );

      return orders.reduce((acc, order) => acc + order.price, 0);
    },

    prospectedProportion() {
      return {
        labels: ["Commandes", "Prospectés"],
        datasets: [
          {
            backgroundColor: ["#255dc8", "#18bdb4"],
            data: [this.ordersOther.length, this.orders.length],
          },
        ],
      };
    },

    prospectedProportionRatio() {
      return (
        (100 * this.orders.length) /
        (this.orders.length + this.ordersOther.length)
      ).toFixed(2);
    },

    payedOrders() {
      return this.orders.filter((o) => o.status !== "pending").length;
    },

    prospectedPayedProportion() {
      return {
        labels: ["Prospectés", "Payées"],
        datasets: [
          {
            backgroundColor: ["#255dc8", "#18bdb4"],
            data: [this.orders.length, this.payedOrders],
          },
        ],
      };
    },
    prospectedPayedProportionRatio() {
      return ((100 * this.payedOrders) / this.orders.length).toFixed(2);
    },

    prospectedWithOrderProportion() {
      return {
        labels: ["Clients avec commande(s)", "Clients sans commande(s)"],
        datasets: [
          {
            backgroundColor: ["#255dc8", "#18bdb4"],
            data: [this.clientWithOrder.length, this.clientWithoutOrder.length],
          },
        ],
      };
    },
    prospectedwithOrderProportionRatio() {
      return (
        (100 * this.clientWithOrder.length + this.clientWithoutOrder.length) /
        this.clientWithOrder.length
      ).toFixed(2);
    },
  },
};
</script>

<style scoped>
.custom-card {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #374767;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 5px 15px 2px rgba(255, 255, 255, 0.77);
}
.pills {
  border-radius: 100px;
  padding: 3px 20px;
  display: block;
  display: inline-block;
}

.blue {
  background-color: #255dc8;
  color: white;
}

.green {
  background-color: #18bdb4;
  color: white;
}
</style>
